import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'Protupožarno staklo - Proizvodi | Protupožarna zaštita',
  description: 'Protupožarno/vatrootporno staklo kao i protupožarni zidovi, ima zadaću štititi evakuacijske puteve, ljude i imovinu. Dijelimo ga u dvije osnovne skupine, protupožarno/vatrootporno staklo koje sprječava širenje dima i vatre i protupožarno/vatrootporno staklo koje osim sprječavanja širenja dima i vatre, sprječava i toplinsko zračenje. Osim sigurnosne zadaće, danas protupožarno/vatrootporno staklo mora zadovoljiti zahtjeve estetskog oblikovanja unutrašnjeg prostora. Protupožarno/vatrootporno staklo, ugrađuje se isključivo prema tehničkim detaljima ispitanog sustava.',
}

const Category = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
        { link: `/proizvodi/protupozarno-staklo/`, name: 'Protupožarno staklo'},
      ]}
    />
    <Hero
      description="Protupožarno/vatrootporno staklo kao i protupožarni zidovi, ima zadaću štititi evakuacijske puteve, ljude i imovinu. Dijelimo ga u dvije osnovne skupine, protupožarno/vatrootporno staklo koje sprječava širenje dima i vatre i protupožarno/vatrootporno staklo koje osim sprječavanja širenja dima i vatre, sprječava i toplinsko zračenje. Osim sigurnosne zadaće, danas protupožarno/vatrootporno staklo mora zadovoljiti zahtjeve estetskog oblikovanja unutrašnjeg prostora. Protupožarno/vatrootporno staklo, ugrađuje se isključivo prema tehničkim detaljima ispitanog sustava."
      slim
      subtitle="Protupožarno staklo"
      title="Proizvodi"
    />
    <Products activeCategory="protupozarno-staklo" />
  </Page>
)

export default Category
